<template>
  <div>
    <v-dialog width="600" v-model="dialog">
      <v-card>
        <v-card-title style="font-size:30px" class="secondary white--text  justify-center">
         {{$t('deleteconfirm')}}
        </v-card-title>
        <v-card-text>
          <p style="font-size: 16px" class="pt-5 text-center" v-if="item">
            {{ $t("suredelete") }} "Group ID #{{ item.groupID }}" ?
          </p>
          <p
            v-if="item.haveDepartment == true"
            style="color: red; font-size:16px"
            class="text-center mt-2"
          >
            {{ $t("hasDept") }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4" v-if="item.haveDepartment == true">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="text-capitalize"
            style="font-size: 20px"
            width="150"
            @click="$emit('close')"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-actions class="mt-3 pb-4" v-else>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize mr-3"
            width="150"
            style="font-size: 20px"
            text
            @click="$emit('close')"
            >{{$t('cancel')}}</v-btn
          >
          <v-btn
            color="error"
            class="text-capitalize"
            width="150"
            style="font-size: 20px"
            @click="$emit('confirm')"
            >{{$t('confirm')}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
</style>