<template>
  <div>
    <v-dialog v-model="addImportDialog" width="1000" scrollable persistent>
      <v-card>
        <v-card-title
          style="font-size: 20px; height: 60px"
          class="justify-start"
        >
          <p>
            <v-icon class="mb-1" style="color: #424242; font-size: 28px"
              >mdi-account-plus-outline</v-icon
            >
            {{ $t("importusers") }}
          </p>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="Cancel()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-progress-circular
              v-if="loading == true"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
            <v-row class="mt-5 ml-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                <label for="filetoupload" class="font-style">
                  {{ $t("filetoupload") }}
                </label>
                <v-row class="mt-2">
                  <v-col cols="12" lg="7">
                    <v-card
                      width="290"
                      height="130"
                      style="
                        border: 2px dashed #c0c0c0;
                        box-shadow: unset;
                        background: #f8f8f8 0% 0% no-repeat padding-box;
                      "
                    >
                      <v-img
                        src="@/assets/Component 32 – 256.png"
                        width="55"
                        height="55"
                        class="mx-auto center"
                        style="margin-top: 1.5rem"
                      ></v-img>
                      <label
                        class="d-flex"
                        for="browsefiles"
                        style="justify-content: center; margin-top: 10px"
                        v-if="ExcelButtonDisable"
                      >
                        {{ ExcelFilename }}
                        <v-icon
                          @click="DeleteExcelfile"
                          color="error"
                          width="20"
                          height="20"
                          v-if="ExcelButtonDisable"
                          style="
                            cursor: pointer;
                            border: 1px;
                            margin-left: 10px;
                          "
                          >mdi-delete</v-icon
                        >
                      </label>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="5">
                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                      class="d-none"
                      ref="excelfile"
                      @change="onFileChanged($event)"
                    />
                    <v-btn
                      :disabled="ExcelButtonDisable"
                      rounded-lg
                      color="secondary"
                      @click="
                        ($refs.excelfile.value = null), $refs.excelfile.click()
                      "
                      style="font-size: 16px"
                      class="text-capitalize white--text"
                      ><v-icon class="mr-2">mdi-folder-search-outline</v-icon
                      >{{ $t("selectexcelfile") }}</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="9">
                    <label for="group" class="font-style"
                      >{{ $t("group") }}<span style="color: red">*</span></label
                    >
                    <v-select
                      style="margin-top: 20px"
                      :items="departmentList"
                      item-text="groupName"
                      item-value="groupID"
                      label="Please Select"
                      outlined
                      dense
                      hide-details="auto"
                      v-model="department"
                    ></v-select>
                    <label
                      for="permission"
                      class="font-style"
                      style="color: red; font-size: 12px"
                      v-if="groupcheck"
                      >{{ "Please select Group permission for User" }}</label
                    >
                  </v-col>
                </v-row>
              </v-col>

              <v-divider vertical></v-divider>
              <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                <v-row>
                  <v-col>
                    <label for="exceltemplateforupload" class="font-style">
                      {{ $t("exceltemplateforupload") }}
                    </label>
                  </v-col>
                </v-row>
                <br />
                <img
                  alt
                  @click="DownloadTemplate"
                  src="@/assets/Iconly-Bold-Paper-Download.png"
                  style="cursor: pointer; margin-bottom: -5px"
                />
                <label
                  for="downloadtemplate"
                  @click="DownloadTemplate"
                  style="
                    font-size: 20px;
                    color: #a6cc39;
                    margin-left: 15px;
                    cursor: pointer;
                  "
                  >{{ $t("downloadtemplate") }}</label
                >
              </v-col>
            </v-row>
            <v-row class="mt-5 ml-5"> </v-row>
            <v-divider class="ml-5 mt-10"></v-divider>
          </v-form>
        </v-card-text>
        <v-card-actions class="mb-5">
          <v-spacer></v-spacer>
          <v-btn outlined text class="text-capitalize mr-2" @click="Cancel()">{{
            $t("cancel")
          }}</v-btn>
          <v-btn class="text-capitalize" color="secondary" @click="Upload()">{{
            $t("upload")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ValidationDialog" width="600" scrollable persistent>
      <v-card>
        <v-card-title
          style="font-size: 30px; color: #ffffff; height: 60px"
          class="secondary justify-center"
        >
          <p>{{ $t("validation") }}</p>
        </v-card-title>
        <v-card-text style="height: 440px">
          <v-form ref="form" v-model="valid">
            <v-progress-circular
              v-if="loading == true"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
            <v-row class="mt-5 ml-5" v-if="successvalue != 0">
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="passvalidation" class="font-style">{{
                  $t("passvalidation")
                }}</label>
              </v-col>
            </v-row>
            <v-row class="ml-10" v-if="successvalue != 0">
              <label for="successuserdata" class="font-style ml-5">{{
                successuserdata
              }}</label>
            </v-row>
            <v-row class="ml-10" v-if="successvalue != 0">
              <v-checkbox
                v-model="SendMailCheck"
                color="#a6cc39"
                class="chk-input ml-5"
                hide-details
              ></v-checkbox>
              <label for="invalid" class="font-style mt-5">{{
                  $t("sendemailtheaccount")
                }}</label>
            </v-row>
            <v-row class="mt-5 ml-5" v-if="lostvalue != 0">
              <v-col cols="12" xs="12" sm="12" md="6">
                <label for="invalid" class="font-style">{{
                  $t("invalid")
                }}</label>
              </v-col>
            </v-row>
            <v-row class="ml-10 mb-5" v-if="lostvalue != 0">
              <label for="invaliduserdata" class="font-style ml-5">{{
                invaliduserdata
              }}</label>
            </v-row>
            <v-row
              class="ml-15"
              v-for="item in ErrorMessage"
              :key="item.message"
            >
              <label for="message" style="font-size: 14px; color: #ff0000">{{
                item.message
              }}</label>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 mb-5">
          <v-spacer></v-spacer>
          <v-btn text class="text-capitalize mr-3" @click="Cancel()">{{
            $t("cancel")
          }}</v-btn>
          <v-btn class="text-capitalize" color="secondary" @click="submit()">{{
            $t("submit")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import exceljs from "exceljs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
export default {
  directives: { mask },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    OldUsernameList: Array,
    OldEmpidList: Array,
  },
  computed: {
    ...mapGetters(["departmentList"]),
    addImportDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    groupRules() {
      return [(v) => !!v || "Please select Group permission for User."];
    },
  },
  data: () => ({
    valid: false,
    mailMessage: "",
    loading: false,
    isExist: false,
    ExcelButtonDisable: false,
    ExcelFilename: "",
    department: 0,
    ValidationDialog: false,
    successuserdata: "",
    SendMailCheck: true,
    invaliduserdata: "",
    checkboxlabel: "",
    ErrorMessage: [
      // { message: '-EmployeeID is duplicated in row1,row3' },
      // { message: '-Username is dupliacted row4 and row5' },
      //  { message: '-Username is blank row4 and row5' },
    ],
    ImportList: [],
    chosenexcelfile: null,
    departmentdatalists: [],
    successvalue: 0,
    lostvalue: 0,
    groupcheck: false,
    //   OldUsernameList:[],
    //  OldEmpidList:[],
    PricingData: [],
    ActiveEmployeeCount: 0,
  }),
  mounted() {
    this.getDepartment();
    this.getPricingData();
    this.getCountForEmployee();
    //this.checkboxlabel = this.$t("sendemailtheaccount");
  },
  methods: {
    async getPricingData() {
      const res = await this.$axios.get(
        `${this.web_urlV4}Package/GetPackageOfCompanyCustomer?CompanyId=` +
          localStorage.getItem("companyID")
      );
      this.PricingData = res.data.data;
    },
    async getCountForEmployee() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetCompanyActiveUserMP3MP4Count?id=` +
          localStorage.getItem("companyID")
      );
      this.ActiveEmployeeCount = res.data.data[0].activeEmployee;
    },
    onFileChanged(e) {
      let files = e.target.files,
        f = files[0];

      let reader = new FileReader();
      reader.onload = (g) => {
        let data = new Uint8Array(g.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[sheetName];
        this.chosenexcelfile = XLSX.utils.sheet_to_json(worksheet);

        this.ExcelButtonDisable = true;
        this.ExcelFilename = f.name;
      };
      reader.readAsArrayBuffer(f);
    },
    FilterData(array, i) {
      let filtered = array.filter((x) => x.No == i);
      return filtered;
    },
    Upload() {
      let self = this;
      self.successvalue = 0;
      self.lostvalue = 0;
      let temporaryImportList = [];
      if (
        self.department != 0 &&
        self.ExcelButtonDisable === true &&
        self.chosenexcelfile != 0
      ) {
        self.ValidationDialog = true;
        let groupname = self.CheckGroupname(self.department);
        self.loading = true;
        self.chosenexcelfile.forEach((element, i) => {
          if (element["EmployeeID*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("employeeblank") + (i + 2),
                No: 1,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 1);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("employeeblank") + (i + 2),
                  No: 1,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 1) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          // else{
          //   let filter = self.OldEmpidList.filter(x=>x == element["EmployeeID*"].toString());
          //   if(filter.length!=0){
          //     self.ErrorMessage.push({message:self.$t("duplicatedemployeeid")+(i+2)});
          //   }
          // }

          if (element["FirstName*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("firstnameblank") + (i + 2),
                No: 2,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 2);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("firstnameblank") + (i + 2),
                  No: 2,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 2) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          if (element["LastName*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("lastnameblank") + (i + 2),
                No: 3,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 3);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("lastnameblank") + (i + 2),
                  No: 3,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 3) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          if (element["Email*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("emailblank") + (i + 2),
                No: 4,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 4);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("emailblank") + (i + 2),
                  No: 4,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 4) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          if (element["Telephone*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("telephoneblank") + (i + 2),
                No: 5,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 5);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("telephoneblank") + (i + 2),
                  No: 5,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 5) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          if (element["Username*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("usernameblank") + (i + 2),
                No: 6,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 6);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("usernameblank") + (i + 2),
                  No: 6,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 6) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          // else{
          //   let filter = self.OldUsernameList.filter(x=>x == element["Username*"].toString());
          //   if(filter.length!=0){
          //     self.ErrorMessage.push({message:self.$t("duplicatedusername")+(i+2)});
          //   }
          // }
          if (element["Password*"] == undefined) {
            if (self.ErrorMessage.length == 0) {
              self.ErrorMessage.push({
                message: this.$t("passwordblank") + (i + 2),
                No: 7,
              });
            } else {
              let filtered = self.FilterData(self.ErrorMessage, 7);
              if (filtered.length == 0) {
                self.ErrorMessage.push({
                  message: this.$t("passwordblank") + (i + 2),
                  No: 7,
                });
              } else {
                self.ErrorMessage.forEach((element1) => {
                  if (element1.No == 7) {
                    element1.message = element1.message + ", " + (i + 2);
                  }
                });
              }
            }
          }
          temporaryImportList.push({
            EmployeeID: element["EmployeeID*"],
            FirstName: element["FirstName*"],
            LastName: element["LastName*"],
            Email: element["Email*"],
            Telephone: element["Telephone*"],
            Username: element["Username*"],
            Password: element["Password*"],
            No: i + 2,
          });
        });
        let importlist_one = temporaryImportList;
        for (let index = 0; index < importlist_one.length; index++) {
          if (importlist_one.length == 1) {
            let filter = self.OldUsernameList.filter(
              (x) => x == importlist_one[index].Username
            );
            if (filter.length != 0) {
              self.ErrorMessage.push({
                message: self.$t("duplicatedusername") + 2,
              });
            }

            let filteremp = self.OldEmpidList.filter(
              (x) => x == importlist_one[index].EmployeeID
            );
            if (filteremp.length != 0) {
              self.ErrorMessage.push({
                message: self.$t("duplicatedemployeeid") + 2,
              });
            }
          }
          for (
            let inner = 1 + index;
            inner < temporaryImportList.length;
            inner++
          ) {
            if (temporaryImportList[inner].EmployeeID != undefined) {
              if (
                importlist_one[index].EmployeeID ==
                temporaryImportList[inner].EmployeeID
              ) {
                temporaryImportList[index].FirstName = undefined;
                temporaryImportList[inner].FirstName = undefined;
                let filter = this.ErrorMessage.filter((x) => x.No == 8 + index);
                if (filter.length == 0) {
                  this.ErrorMessage.push({
                    message:
                      this.$t("duplicatedemployeeid") +
                      importlist_one[index].No +
                      ", " +
                      temporaryImportList[inner].No,
                    No: index + 8,
                  });
                } else {
                  this.ErrorMessage.forEach((element1) => {
                    if (element1.No == index + 8) {
                      element1.message =
                        element1.message + ", " + temporaryImportList[inner].No;
                    }
                  });
                }
                temporaryImportList[inner].EmployeeID = undefined;
              } else {
                let filter = self.OldEmpidList.filter(
                  (x) => x == temporaryImportList[inner].EmployeeID
                );
                if (filter.length != 0) {
                  let filterdata = this.ErrorMessage.filter(
                    (x) => x.No == 8 + index
                  );
                  if (filterdata.length == 0) {
                    this.ErrorMessage.push({
                      message:
                        this.$t("duplicatedemployeeid") +
                        importlist_one[index].No +
                        ", " +
                        temporaryImportList[inner].No,
                      No: index + 8,
                    });
                  } else {
                    this.ErrorMessage.forEach((element1) => {
                      if (element1.No == index + 8) {
                        element1.message =
                          element1.message +
                          ", " +
                          temporaryImportList[inner].No;
                      }
                    });
                  }
                  temporaryImportList[inner].EmployeeID = undefined;
                }
              }
            }

            if (temporaryImportList[inner].Username != undefined) {
              if (
                importlist_one[index].Username ==
                temporaryImportList[inner].Username
              ) {
                let filter = this.ErrorMessage.filter(
                  (x) => x.No == temporaryImportList.length + 9 + index
                );
                if (filter.length == 0) {
                  this.ErrorMessage.push({
                    message:
                      this.$t("duplicatedusername") +
                      importlist_one[index].No +
                      ", " +
                      temporaryImportList[inner].No,
                    No: index + 9 + temporaryImportList.length,
                  });
                } else {
                  this.ErrorMessage.forEach((element1) => {
                    if (element1.No == temporaryImportList.length + index + 9) {
                      element1.message =
                        element1.message + ", " + temporaryImportList[inner].No;
                    }
                  });
                }
                temporaryImportList[inner].Username = undefined;
              }
            }
            // else{
            //          let filter = self.OldUsernameList.filter(x=>x == temporaryImportList[inner].Username);
            //           if(filter.length!=0){
            //            // self.ErrorMessage.push({message:self.$t("duplicatedusername")+(i+2)});
            //              let filterdata = this.ErrorMessage.filter(x=>x.No==(temporaryImportList.length+9+index));
            //         if(filterdata.length==0){
            //           this.ErrorMessage.push({
            //         message:this.$t("duplicatedusername")+importlist_one[index].No+", "+temporaryImportList[inner].No,
            //         No:index+9+temporaryImportList.length
            //       });
            //       }else{
            //         this.ErrorMessage.forEach(element => {
            //           if(element.No == (temporaryImportList.length+index+9)){
            //             element.message = element.message+", "+temporaryImportList[inner].No
            //           }
            //         });
            //       }
            //           }
            //     }
          }
        }
        let showerrmsg = this.$t("employeecantbecreated");
        let showmsg = this.$t("employeewillbeadded");
        temporaryImportList.forEach((element) => {
          if (
            element.EmployeeID != undefined &&
            element.FirstName != undefined &&
            element.LastName != undefined &&
            element.Email != undefined &&
            element.Telephone != undefined &&
            element.Username != undefined &&
            element.Password != undefined
          ) {
            let filterUsername = self.OldUsernameList.filter(
              (x) => x == element.Username.toString()
            );
            let filterEmpid = self.OldEmpidList.filter(
              (x) => x == element.EmployeeID.toString()
            );
            if (filterUsername.length == 0 && filterEmpid.length == 0) {
              self.ImportList.push({
                employeeID: element.EmployeeID.toString(),
                firstName: element.FirstName.toString(),
                lastName: element.LastName.toString(),
                email: element.Email.toString(),
                telephone: element.Telephone.toString(),
                username: element.Username.toString(),
                password: element.Password.toString(),
              });
              self.successvalue = self.successvalue + 1;
              let secondmsg = showmsg.replace("{0}", self.successvalue);
              self.successuserdata = secondmsg.replace("{1}", groupname);
            } else {
              self.lostvalue = self.lostvalue + 1;
              self.invaliduserdata = showerrmsg.replace("{0}", self.lostvalue);
            }
          } else {
            self.lostvalue = self.lostvalue + 1;
            self.invaliduserdata = showerrmsg.replace("{0}", self.lostvalue);
          }
        });
      } else {
        self.groupcheck = true;
        alert("The data is incomplete, please check again!");
      }
      this.loading = false;
    },
    async getDepartment() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      this.departmentdatalists = res.data.data;
      this.$store.commit("savedeparmentList", res.data.data);
    },
    DeleteExcelfile() {
      this.ExcelButtonDisable = false;
      this.ExcelFilename = "";
    },
    async ChkUsername(username, i) {
      let checkuserdata = "";
      const res = await this.$axios.post(
        `${this.web_url}Company/CheckUser?username=` +
          username +
          "&abbr=" +
          localStorage.getItem("Abbreviation")
      );
      if (res.data.data == false) {
        this.ErrorMessage.push({
          message: "-Username is duplicated in row" + i,
        });
        checkuserdata = "1";
      }
      return checkuserdata;
    },
    async emailExists() {
      const res = await this.$axios.post(`${this.web_url}User/CheckUser`, {
        email: this.user.email,
      });
      if (res.data.data) {
        this.mailMessage = res.data.message;
      } else {
        this.mailMessage = "";
      }
    },
    CheckGroupname(id) {
      let gpname = this.departmentdatalists.find(
        (x) => x.groupID === parseInt(id)
      ).groupName;
      return gpname;
    },
    DuplicateItem(arr) {
      const unique = [...new Map(arr.map((m) => [m.EmployeeID, m])).values()];
      const nextunique = [
        ...new Map(unique.map((m) => [m.Username, m])).values(),
      ];
      return nextunique;
    },
    Cancel() {
      this.addImportDialog = false;
      this.ValidationDialog = false;
      this.ExcelButtonDisable = false;
      this.department = 0;
      this.ErrorMessage = [];
      this.ImportList = [];
      this.successuserdata = "";
      this.invaliduserdata = "";
      this.successvalue = 0;
      this.lostvalue = 0;
      this.groupcheck = false;
      this.SendMailCheck = true;
      this.getPricingData();
      this.getCountForEmployee();
    },
    async submit() {
      let self = this;

      if (self.ImportList.length != 0) {
        if (
          self.ActiveEmployeeCount + self.ImportList.length <=
          self.PricingData.activeEmpId
        ) {
          self.AddImportFunction();
        } else {
          alert(
            "The active employees are reach limit amount. Contact Seedkm administrator to enhance usage"
          );
          self.Cancel();
        }
      } else {
        alert("Please add valid Import Users!");
      }
    },
    async AddImportFunction() {
      let self = this;
      self.loading = true;
      const res = await this.$axios.post(
        `${self.web_urlV4}User/ImportUserList`,
        {
          importUserList: self.ImportList,
          emailCheck: self.SendMailCheck,
          groupID: self.department,
          createBy: localStorage.getItem("userName"),
          companyID: localStorage.getItem("companyID"),
        }
      );
      if (res) {
        self.Cancel();
        this.$emit("confirm");
      }
      self.loading = false;
    },
    async getEmpIdAndUsername() {
      const res = await this.$axios.get(
        `${this.web_url}Company/GetEmpIDAndUsername?id=` +
          localStorage.getItem("companyID")
      );
      this.OldUsernameList = res.data.data;
      this.OldEmpidList = res.data.data1;
    },
    async DownloadTemplate() {
      const headers = [
        "EmployeeID*",
        "FirstName*",
        "LastName*",
        "Email*",
        "Telephone*",
        "Username*",
        "Password*",
      ];

      const fileName = `ImportUserTemplate.xlsx`;
      const data = [];
      data.push(headers);

      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("Import Users", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #424242;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .v-btn__content {
  font-size: 16px;
}
</style>

<style>
/* .v-card__text{
    padding: 0px!important;
  } */
</style>
