<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="mb-1 mr-2" style="font-size: 28px; color: #424242"
              >mdi-account-multiple-plus-outline</v-icon
            >{{ $t("newdepartment") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              $store.commit('clearSelected'),
                $emit('close'),
                (department = []),
                $refs.form.reset()
            "
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="9">
                <label for="departmentid" class="font-style"
                  >{{ $t("departmentid")
                  }}<span style="color: red">*</span></label
                >
                <v-text-field
                  :rules="idRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="department.id"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="departmentnamesm" class="font-style"
                  >{{ $t("departmentnamesm") }}
                  <span style="color: red">*</span></label
                >
                <v-text-field
                  :rules="nameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="department.name"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="memberlistsm" class="font-style"
                  >{{ $t("memberlistsm") }}
                </label>
                <v-combobox
                  append-icon=""
                  :items="users"
                  readonly
                  multiple
                  item-text="fullName"
                  item-value="empID"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="department.list"
                  class="mt-2"
                ></v-combobox>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="secondary"
                  width="120"
                  block
                  class="mt-8 font-style text-capitalize"
                  @click="
                    $emit('close'),
                      $store.commit('closeAddDepartmentDialog'),
                      $store.commit('DepartmentTable', true),
                      $store.commit('saveSearchandBtnFlag', false)
                  "
                  >{{ $t("add") }}</v-btn
                >
              </v-col>
              <v-col cols="9">
                <label for="role" class="font-style"
                  >{{ $t("role") }} <span style="color: red">*</span></label
                >
                <v-select
                  v-model="department.level"
                  :items="levelList"
                  flat
                  hide-details
                  item-text="roleName"
                  item-value="userRoleID"
                  hide-no-data
                  :rules="levelRules"
                  outlined
                  dense
                  clearable
                  multiple
                  class="mt-2"
                  placeholder="-- Please select --"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="getSelectAll"
                      style="border-bottom: 1px solid #00000026"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            department.level
                              ? department.level.length > 0
                                ? '#a6cc39'
                                : ''
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      v-if="index === 0"
                      style="background: rgba(121, 190, 67, 0.5)"
                    >
                      <span>{{ item.roleName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ department.level.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            width="100"
            class="text-capitalize btnfont-style"
            @click="
              $store.commit('clearSelected'),
                $emit('close'),
                (department = []),
                $refs.form.reset()
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="secondary"
            width="100"
            class="text-capitalize btnfont-style"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["selectedMemberList", "levelList"]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    watchingSelected() {
      return this.selectedMemberList;
    },
    idRules() {
      return [(v) => !!v || "ID is required"];
    },
    nameRules() {
      return [(v) => !!v || "Name is required"];
    },
    memberRules() {
      return [(v) => !!v || "Member list is required"];
    },
    levelRules() {
      return [(v) => !!v || "Level is required"];
    },
    selectAll() {
      return this.department.level
        ? this.department.level.length === this.levelList.length
        : 0;
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
  },
  data: () => ({
    searchgroup: null,
    valid: false,
    levels: [],
    department: {
      id: "",
      name: "",
      list: "",
      // level: "",
      level: [],
    },
    selected: [],
    group: [],
    users: [],
  }),
  mounted() {
    this.getRoleAndPermission();
    this.getUserData();
  },
  watch: {
    watchingSelected(val) {
      if (val) {
        this.getMemberList();
      }
    },
  },
  methods: {
    getSelectAll() {
      if (this.department.level.length == 0) {
        this.department.level = this.levelList.map(
          ({ userRoleID }) => userRoleID
        );
      } else {
        this.department.level = [];
      }
    },
    async getRoleAndPermission() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.levels = res.data.data;
      this.$store.commit("saveroleList", res.data.data);
    },

    async getUserData() {
      const res = await this.$axios.get(
        `${this.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
          localStorage.getItem("companyID")
      );
      this.users = res.data.data;
    },

    getMemberList() {
      let tempList = [];
      for (const element of this.selectedMemberList) {
        tempList.push(element.fullName);
      }
      this.department.list = tempList;
      this.selected = this.selectedMemberList;
    },
    async getDept() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("savedeparmentList", res.data.data);
      let dept = [];
      for (const element of res.data.data) {
        dept.push({
          departmentID: element["groupID"],
          departmentName: element["groupName"],
        });
      }
      this.$store.commit("editeparmentList", dept);
    },
    async submit() { 
      if (this.$refs.form.validate()) {
        let empGroup = [];
        for (const element of this.selectedMemberList) {
          empGroup.push({
            employeeID: element.userID,
            groupID: 0,
          });
        }

        let roleGroup = [];
        for (const element of this.department.level) {
          roleGroup.push({
            roleID: element,
            companyId: localStorage.getItem("companyID"),
            groupID: 0,
          });
        }
        await this.$axios.post(`${this.web_url}Group/AddGroup`, {
          companyId: localStorage.getItem("companyID"),
          groupName: this.department.name,
          uGroupID: this.department.id,
          // roleID: this.department.level.userRoleID,
          groupEmployee: empGroup,
          groupRole: roleGroup,
        });
        this.department = [];
        this.$refs.form.reset();
        this.getDept();
        this.$emit("confirm");
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
/* >>>.v-btn__content{
  font-size: 20px;
} */
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>

<style scoped>
/* width */
::v-deep ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::v-deep ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::v-deep ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::v-deep ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
