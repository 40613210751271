<template>
  <div>
    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p>
            <v-icon class="mb-1 mr-1" style="font-size: 25px; color: #424242"
              >mdi-account-plus-outline</v-icon
            >
            {{ $t("editlevel") }}
          </p>

          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="$emit('close')"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-5">
              <v-col cols="9">
                <label for="levelid" class="font-style">
                  {{ $t("levelid") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="levelidRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="id"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="levelnamesm" class="font-style">
                  {{ $t("levelnamesm") }}
                  <span style="color: red">*</span>
                </label>
                <v-text-field
                  :rules="levelRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="permission" class="font-style">
                  {{ $t("permission") }}
                  <span style="color: red">*</span>
                </label>
                <v-combobox
                  :rules="permissionRules"
                  v-model="selectedPermission"
                  :items="permissions"
                  item-text="permissionName"
                  item-value="permissionID"
                  outlined
                  dense
                  readonly
                  hide-details="auto"
                  append-icon
                ></v-combobox>
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="secondary"
                  class="text-capitalize mt-7 font-style"
                  block
                  @click="addPermission()"
                  >{{ $t("add") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-5">
          <!-- $refs.form.reset() -->
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            outlined
            @click="
              $emit('close'), $store.commit('removeSelectedLevelPermission')
            "
            >{{ $t("cancel") }}</v-btn
          >
          <!-- $refs.form.resetValidation() -->
          <v-btn
            class="text-capitalize btnfont-style"
            color="secondary"
            width="100"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PermissionEdit
      :show="showPermissionDialog"
      @close="(showPermissionDialog = false), (dialog = true)"
      ref="editDataCall"
      @updatePermissions="getSelectedPermission()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PermissionEdit from "./PermissionEdit.vue";
export default {
  components: {
    PermissionEdit,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    startingActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "editLevelPermission",
      "selectedLevelPermission",
      "editLevel",
    ]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    checkEditLevel() {
      return this.selectedLevelPermission;
    },
    id: {
      get() {
        return this.editLevelPermission.roleCode;
      },
      set(e) {
        this.level.id = e;
      },
    },
    name: {
      get() {
        return this.editLevelPermission.roleName;
      },
      set(e) {
        this.level.name = e;
      },
    },
  },
  data: () => ({
    showPermissionDialog: false,
    valid: true,
    selectedPermission: [],
    permissions: [],
    level: {
      id: null,
      name: "",
    },
    levelidRules: [(v) => !!v || "Level ID is required"],
    levelRules: [(v) => !!v || "Level Name is required"],
    permissionRules: [(v) => !!v || "Permission is required"],
    selectedChangedPermission: [],
  }),
  watch: {
    startingActive(val) {
      if (val) {
        this.getactivePermission();
      }
    },
    checkEditLevel(val) {
      if (val) {
        this.getSelectedPermission();
      }
    },
  },
  mounted() {
    this.getPermission();
  },
  methods: {
    async getPermission() {
      const res = await this.$axios.get(
        `${this.web_url}Permission/GetPermissionAll`
      );
      this.permissions = res.data.data;
    },

    getactivePermission() {
      this.selectedPermission = [];
      this.selectedChangedPermission = [];

      //data bind for permission name
      this.selectedPermission.push(
        this.editLevelPermission.rolePermission.permissionName
      );

      //for update permission data bind
      this.selectedChangedPermission.push(
        this.editLevelPermission.rolePermission
      );
      this.$emit("stopStartingActive");
    },

    getSelectedPermission() {
      this.selectedPermission = [];
      this.selectedChangedPermission = [];

      let per_type =
        this.selectedLevelPermission.mobileOrWebUsage_Permission == 1
          ? "All"
          : "Userview + App";

      if (this.selectedLevelPermission.length == 0) {
        this.selectedPermission = [];
      } else {
        this.selectedPermission.push(per_type);
      }

      //prepare for update data bind
      let permission_data = {
        userRolePermission_ID:
          this.selectedLevelPermission.userRolePermission_ID,
        userRoleID: 0,
        permissionID: this.selectedLevelPermission.mobileOrWebUsage_Permission, //usageID 1(web+mobile) 0r 2(mobile only)
        //permissionName: per_type,

        createBy: localStorage.getItem("userName"),
        updateBy: localStorage.getItem("userName"),

        libraryFlag: this.selectedLevelPermission.library_Checked,
        libraryView:
          this.selectedLevelPermission.library_Permission == 1 ? true : false,

        lessonLearnFlag: this.selectedLevelPermission.lessonlearn_Checked,
        lessonLearnView:
          this.selectedLevelPermission.lessonLearn_Permission == 1
            ? true
            : false,

        courseFlag: this.selectedLevelPermission.course_Checked,
        courseView:
          this.selectedLevelPermission.course_Permission == 1 ? true : false,
        quizFlag: this.selectedLevelPermission.quiz_Checked,
        quizView:
          this.selectedLevelPermission.quiz_Permission == 1 ? true : false,

        communityFlag: this.selectedLevelPermission.community_Checked,
        communityView:
          this.selectedLevelPermission.community_Permission == 1 ? true : false,

        eventFlag: this.selectedLevelPermission.event_Checked,
        eventView:
          this.selectedLevelPermission.event_Permission == 1 ? true : false,

        bannerFlag: this.selectedLevelPermission.banner_Checked,
        bannerView:
          this.selectedLevelPermission.banner_Permission == 1 ? true : false,

        feedbackFlag: this.selectedLevelPermission.feedbackbox_Checked,
        feedbackView:
          this.selectedLevelPermission.feedbackbox_Permission == 1
            ? true
            : false,

        userManagementFlag: this.selectedLevelPermission.usermanage_Checked,
        userManagementView:
          this.selectedLevelPermission.usermanage_Permission == 1
            ? true
            : false,

        companyProfileFlag: this.selectedLevelPermission.companyProfile_Checked,
        companyProfileView:
          this.selectedLevelPermission.companyProfile_Permission == 1
            ? true
            : false,

        storeFlag: this.selectedLevelPermission.store_Checked,
        storeView:
          this.selectedLevelPermission.store_Permission == 1 ? true : false,

        skillMatrixFlag: this.selectedLevelPermission.skillMatrix_Checked,
        skillMatrixView:
          this.selectedLevelPermission.skillMatrix_Permission == 1
            ? true
            : false,

        dashboardFlag: this.selectedLevelPermission.dashboard_Checked,
        dashboardView:
          this.selectedLevelPermission.dashboard_Permission == 1 ? true : false,

        sendNotificationFlag: this.selectedLevelPermission.sendnoti_Checked,
        sendNotificationView:
          this.selectedLevelPermission.sendnoti_Permission == 1 ? true : false,

        settingNotificationFlag:
          this.selectedLevelPermission.settingNoti_Checked,
        settingNotificationView:
          this.selectedLevelPermission.settingNoti_Permission == 1
            ? true
            : false,

        userActivityFlag: this.selectedLevelPermission.userActivity_Checked,
        usageMetricsFlag: this.selectedLevelPermission.usageMetrics_Checked,
        userViewFlag: this.selectedLevelPermission.userView_Checked,
        requestedUserFlag: this.selectedLevelPermission.requestedUserRp_Checked,
        assignmentReportFlag: this.selectedLevelPermission.acReport_Checked,
      };
      //for update permission data bind
      this.selectedChangedPermission.push(permission_data);
    },

    addPermission() {
      // this.$store.commit("changePermissionTable", true); before R11
      this.$store.commit("changeEditLevel", true); //editLevel false ==> go to Add Per
      this.showPermissionDialog = true; //for permission dialog open

      this.$store.commit(
        "saveSelectedLevelPermission",
        this.selectedChangedPermission[0]
      );
      this.$emit("close");
      this.$refs.editDataCall.getPermissionDataFrom_store();
    },
    async getRoleAndPermission() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("saveroleList", res.data.data);
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const request = {
          companyId: localStorage.getItem("companyID"),
          userRoleID: this.editLevelPermission.userRoleID,
          roleCode: this.level.id ? this.level.id : this.id,
          roleName: this.level.name ? this.level.name : this.name,
          userRolePermission: this.selectedChangedPermission[0],
        };
        const res = await this.$axios.post(
          `${this.web_url}RoleAndPermission/UpdateUserRole`,
          request
        );
        if (res.data.status == 0) {
          await this.check_menu_permission(); //for permission update changes.
          this.$emit("confirm");
          this.getRoleAndPermission();
          this.level = {};
          this.selectedPermission = [];
        }
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>
