<template>
  <v-container>
    <div>
      <v-data-table
        v-if="!showPermissionTable"
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="items"
        :search="search"
        hide-default-footer
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="role-table"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            color="#4FB14E"
            style="font-size: 16px"
            text
            class="text-capitalize"
            @click="checkLevelEdit(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
            {{ $t("edit") }}
          </v-btn>
          <v-btn
            color="error"
            style="font-size: 16px"
            text
            v-show="item.no != 1"
            class="text-capitalize"
            @click="checkLevelDelete(item)"
          >
            <v-icon>mdi-delete-outline</v-icon>
            {{ $t("delete") }}
          </v-btn>
        </template>
      </v-data-table>

      <v-row v-if="!showPermissionTable" justify="space-between">
        <v-row class="ma-4 d-flex">
          <v-col cols="auto" class="mr-auto">
            <v-pagination
              circle
              class="pagination"
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </v-col>

          <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
            <p class="mt-3 pr-4">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  class="text-capitalize mt-1"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-row>

      <Add
        :show="addLevelDialog"
        @close="$store.commit('closeLevelAddDialog')"
        @confirm="
          $store.commit('closeLevelAddDialog'),
            $store.commit('removeSelectedLevelPermission'),
            (showMessage.show = true),
            (showMessage.title = $t('success')),
            (showMessage.text = $t('addlevel'));
          getLevel();
        "
      />

      <Edit
        :show="editLevelDialog"
        :startingActive="startingActive"
        @stopStartingActive="startingActive = false"
        @close="$store.commit('closeLevelEditDialog')"
        @confirm="
          $store.commit('closeLevelEditDialog'),
            $store.commit('removeSelectedLevelPermission'),
            $store.commit('changeEditLevel', false),
            (showMessage.show = true),
            (startingActive = false),
            (showMessage.title = $t('success')),
            (showMessage.text = $t('editlevelsuccess'));
          getLevel();
        "
      />

      <SuccessDialog
        :show="showMessage.show"
        :title="showMessage.title"
        :text="showMessage.text"
        @close="showMessage.show = false"
      />

      <Delete
        :show="confirmMessage.show"
        :item="editData"
        @close="confirmMessage.show = false"
        @confirm="
          (showMessage.text = $t('deletelevel')),
            (showMessage.title = $t('success')),
            (confirmMessage.show = false),
            (showMessage.show = true),
            getLevel()
        "
      />
      <PermissionDialog
        :show="permissionDialog"
        title="Error"
        :text="permissionMessage"
        @close="permissionDialog = false"
      />
    </div>
  </v-container>
</template>

<script>
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Delete from "./Delete.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Add,
    Edit,
    Delete,
    // Permission
  },
  props: {
    search: String,
  },
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    startingActive: false,
    addDialog: false,
    editDialog: false,
    dialog: false,
    editData: {},
    items: [],
    //search: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [
      {
        title: 5,
      },
      {
        title: 10,
      },
      {
        title: 20,
      },
      {
        title: 30,
      },
      {
        title: 40,
      },
    ],
    loading: true,
    showMessage: {
      show: false,
      text: "Adding new Level is successful.",
      title: "Success",
    },
    confirmMessage: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters(["addLevelDialog", "editLevelDialog", "showPermissionTable"]),
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          align: "left",
          width: "100",
        },
        {
          text: this.$t("levelid"),
          value: "roleCode",
          align: "left",
          class: "tableColor",
          width: "300",
        },
        {
          text: this.$t("levelnamesm"),
          align: "left",
          value: "roleName",
          class: "tableColor",
        },
        {
          text: this.$t("Manage"),
          align: "left",
          value: "actions",
          class: "tableColor",
          width: "300",
        },
      ];
    },
  },
  mounted() {
    this.getLevel();
  },
  methods: {
    async checkLevelDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.confirmMessage.show = true;
        self.editData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete Level.";
      }
    },
    async checkLevelEdit(item) {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.$store.commit("openLevelEditDialog");
        self.$store.commit("saveEditLevelPermission", item);
        self.startingActive = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to edit Level.";
      }
    },
    async checkLevelAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("usermanagement");
      if (checkPermission) {
        self.$store.commit("openLevelAddDialog");
        self.$store.commit("changeEditLevel", false);
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add Level.";
      }
    },
    async getLevel() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
            localStorage.getItem("companyID")
        );
        self.items = res.data.data.map((v, i) => {
          const updatedRolePermission = {
            ...v.rolePermission,
            mobileOrWebUsage_Permission: v.rolePermission ? v.rolePermission.permissionID : null,
          };
          return {
            ...v,
            no: i + 1,
            rolePermission: updatedRolePermission,           
          };
        });
        let itemCount = self.items ? self.items.length : 0;
        self.$emit("itemsCount", itemCount);
        self.$emit("update_Permission");
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to see Role Data.";
      }
      self.loading = false;
    },
  },
};
</script>
<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
}
</style>

<style>
.role-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.role-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
</style>
