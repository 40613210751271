<template>
  <v-container>
    <div>
      <v-row>
        <v-col>
          <span style="font-size: 20px; color: #424242">{{
            $t("SetPermission")
          }}</span>
        </v-col>
        <v-col style="text-align: right">
          <v-btn
            v-if="editPermissionBtnShow"
            @click="editPermission('cancel')"
            outlined
            class="mr-3 text-capitalize"
            style="
              font-size: 16px;
              color: #424242;
              border: 1px solid #c0c0c0;
              border-radius: 8px;
            "
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            v-if="!editPermissionBtnShow"
            @click="editPermission('edit')"
            width="110"
            style="font-size 16px;color: #ffffff;border-radius: 8px;box-shadow: unset;"
            class="text-capitalize"
            color="#A6CC39"
            >{{ $t("edit") }}</v-btn
          >
          <v-btn
            v-if="editPermissionBtnShow"
            @click="CreateorUpdatePermissionSetting(), editPermission('update')"
            width="110"
            style="font-size 16px;color: #ffffff;border-radius: 8px;box-shadow: unset;"
            class="text-capitalize"
            color="#A6CC39"
            >{{ $t("update") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="padding-left: 45px">
          <span>{{ $t("usage") }}</span>
        </v-col>
        <v-col cols="12" style="padding-left: 57px">
          <v-btn
            :disabled="editing_UsageType"
            @click="mobileOrWebUsage_Permission = 2"
            style="font-size 14px;border-radius: 5px;box-shadow: unset;"
            :style="[
              editing_UsageType == true && mobileOrWebUsage_Permission == 2
                ? {
                    'background-color': '#0000001f !important',
                    color: '#424242 !important',
                  }
                : editing_UsageType == false && mobileOrWebUsage_Permission == 1
                ? {
                    'background-color': '#ffffff',
                    color: '#A6CC39',
                    border: '1px solid #A6CC39',
                  }
                : !editing_UsageType == false &&
                  mobileOrWebUsage_Permission == 1
                ? {
                    'background-color': '#ffffff !important',
                    color: '#424242 !important',
                    border: '1px solid #CCCCCC !important',
                  }
                : { 'background-color': '#A6CC39', color: '#ffffff' },
            ]"
            class="text-capitalize mr-3"
            >{{ $t("mobileonly") }}</v-btn
          >
          <v-btn
            :disabled="editing_UsageType"
            @click="mobileOrWebUsage_Permission = 1"
            style="font-size 14px;border-radius: 5px;box-shadow: unset;"
            :style="[
              editing_UsageType == true && mobileOrWebUsage_Permission == 1
                ? {
                    'background-color': '#0000001f !important',
                    color: '#424242 !important',
                  }
                : editing_UsageType == false && mobileOrWebUsage_Permission == 2
                ? {
                    'background-color': '#ffffff',
                    color: '#A6CC39',
                    border: '1px solid #A6CC39',
                  }
                : !editing_UsageType == false &&
                  mobileOrWebUsage_Permission == 2
                ? {
                    'background-color': '#ffffff !important',
                    color: '#424242 !important',
                    border: '1px solid #CCCCCC !important',
                  }
                : { 'background-color': '#A6CC39', color: '#ffffff' },
            ]"
            class="text-capitalize ml-3"
            >{{ $t("web&mobile") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-divider
        style="border: 1px solid #a6cc39"
        class="mt-7 mb-7"
      ></v-divider>
      <v-row class="pl-5 accessright">
        <p>
          {{ $t("accessrights") }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="library_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('library')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!library_Checked || editing"
                @click="library_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && library_Checked == true
                    ? [
                        !library_Checked == true && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !library_Checked == true && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && library_Checked == false
                    ? [
                        !library_Checked == true && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        library_Checked == true && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!library_Checked || editing"
                @click="library_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && library_Checked == true
                    ? [
                        !library_Checked == true && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !library_Checked == true && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : library_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && library_Checked == false
                    ? [
                        !library_Checked == true && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : library_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        library_Checked == true && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !library_Checked == false && library_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="lessonlearn_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('lessonlearn')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!lessonlearn_Checked || editing"
                @click="lessonLearn_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && lessonlearn_Checked == true
                    ? [
                        !lessonlearn_Checked == true &&
                        lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !lessonlearn_Checked == true &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && lessonlearn_Checked == false
                    ? [
                        !lessonlearn_Checked == true &&
                        lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        lessonlearn_Checked == true &&
                        lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!lessonlearn_Checked || editing"
                @click="lessonLearn_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && lessonlearn_Checked == true
                    ? [
                        !lessonlearn_Checked == true &&
                        lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !lessonlearn_Checked == true &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && lessonlearn_Checked == false
                    ? [
                        !lessonlearn_Checked == true &&
                        lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        lessonlearn_Checked == true &&
                        lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !lessonlearn_Checked == false &&
                            lessonLearn_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="course_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('course')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!course_Checked || editing"
                @click="course_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && course_Checked == true
                    ? [
                        !course_Checked == true && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !course_Checked == true && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && course_Checked == false
                    ? [
                        !course_Checked == true && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        course_Checked == true && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!course_Checked || editing"
                @click="course_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && course_Checked == true
                    ? [
                        !course_Checked == true && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !course_Checked == true && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && course_Checked == false
                    ? [
                        !course_Checked == true && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        course_Checked == true && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !course_Checked == false && course_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="quiz_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('quiz')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!quiz_Checked || editing"
                @click="quiz_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && quiz_Checked == true
                    ? [
                        !quiz_Checked == true && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !quiz_Checked == true && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && quiz_Checked == false
                    ? [
                        !quiz_Checked == true && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        quiz_Checked == true && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!quiz_Checked || editing"
                @click="quiz_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && quiz_Checked == true
                    ? [
                        !quiz_Checked == true && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !quiz_Checked == true && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && quiz_Checked == false
                    ? [
                        !quiz_Checked == true && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        quiz_Checked == true && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !quiz_Checked == false && quiz_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="community_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('community')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!community_Checked || editing"
                @click="community_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && community_Checked == true
                    ? [
                        !community_Checked == true && community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !community_Checked == true &&
                            community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && community_Checked == false
                    ? [
                        !community_Checked == true && community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        community_Checked == true && community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!community_Checked || editing"
                @click="community_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && community_Checked == true
                    ? [
                        !community_Checked == true && community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !community_Checked == true &&
                            community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && community_Checked == false
                    ? [
                        !community_Checked == true && community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        community_Checked == true && community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !community_Checked == false &&
                            community_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="event_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('event')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!event_Checked || editing"
                @click="event_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && event_Checked == true
                    ? [
                        !event_Checked == true && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !event_Checked == true && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && event_Checked == false
                    ? [
                        !event_Checked == true && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        event_Checked == true && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!event_Checked || editing"
                @click="event_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && event_Checked == true
                    ? [
                        !event_Checked == true && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !event_Checked == true && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && event_Checked == false
                    ? [
                        !event_Checked == true && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        event_Checked == true && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !event_Checked == false && event_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="banner_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('banner')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!banner_Checked || editing"
                @click="banner_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && banner_Checked == true
                    ? [
                        !banner_Checked == true && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !banner_Checked == true && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && banner_Checked == false
                    ? [
                        !banner_Checked == true && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        banner_Checked == true && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!banner_Checked || editing"
                @click="banner_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && banner_Checked == true
                    ? [
                        !banner_Checked == true && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !banner_Checked == true && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && banner_Checked == false
                    ? [
                        !banner_Checked == true && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        banner_Checked == true && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !banner_Checked == false && banner_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="feedbackbox_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('feedbackbox')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!feedbackbox_Checked || editing"
                @click="feedbackbox_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && feedbackbox_Checked == true
                    ? [
                        !feedbackbox_Checked == true &&
                        feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !feedbackbox_Checked == true &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && feedbackbox_Checked == false
                    ? [
                        !feedbackbox_Checked == true &&
                        feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        feedbackbox_Checked == true &&
                        feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!feedbackbox_Checked || editing"
                @click="feedbackbox_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && feedbackbox_Checked == true
                    ? [
                        !feedbackbox_Checked == true &&
                        feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !feedbackbox_Checked == true &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && feedbackbox_Checked == false
                    ? [
                        !feedbackbox_Checked == true &&
                        feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        feedbackbox_Checked == true &&
                        feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !feedbackbox_Checked == false &&
                            feedbackbox_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="usermanage_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('usermanagement')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!usermanage_Checked || editing"
                @click="usermanage_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && usermanage_Checked == true
                    ? [
                        !usermanage_Checked == true &&
                        usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !usermanage_Checked == true &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && usermanage_Checked == false
                    ? [
                        !usermanage_Checked == true &&
                        usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        usermanage_Checked == true && usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!usermanage_Checked || editing"
                @click="usermanage_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && usermanage_Checked == true
                    ? [
                        !usermanage_Checked == true &&
                        usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !usermanage_Checked == true &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && usermanage_Checked == false
                    ? [
                        !usermanage_Checked == true &&
                        usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        usermanage_Checked == true && usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !usermanage_Checked == false &&
                            usermanage_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="companyProfile_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('companyprofile')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!companyProfile_Checked || editing"
                @click="companyProfile_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && companyProfile_Checked == true
                    ? [
                        !companyProfile_Checked == true &&
                        companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !companyProfile_Checked == true &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && companyProfile_Checked == false
                    ? [
                        !companyProfile_Checked == true &&
                        companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        companyProfile_Checked == true &&
                        companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!companyProfile_Checked || editing"
                @click="companyProfile_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && companyProfile_Checked == true
                    ? [
                        !companyProfile_Checked == true &&
                        companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !companyProfile_Checked == true &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && companyProfile_Checked == false
                    ? [
                        !companyProfile_Checked == true &&
                        companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        companyProfile_Checked == true &&
                        companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !companyProfile_Checked == false &&
                            companyProfile_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="sendnoti_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('sendnotification')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!sendnoti_Checked || editing"
                @click="sendnoti_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && sendnoti_Checked == true
                    ? [
                        !sendnoti_Checked == true && sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !sendnoti_Checked == true &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && sendnoti_Checked == false
                    ? [
                        !sendnoti_Checked == true && sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        sendnoti_Checked == true && sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!sendnoti_Checked || editing"
                @click="sendnoti_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && sendnoti_Checked == true
                    ? [
                        !sendnoti_Checked == true && sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !sendnoti_Checked == true &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && sendnoti_Checked == false
                    ? [
                        !sendnoti_Checked == true && sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        sendnoti_Checked == true && sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !sendnoti_Checked == false &&
                            sendnoti_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="settingNoti_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('setting') + ' - ' + $t('notification')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!settingNoti_Checked || editing"
                @click="settingNoti_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && settingNoti_Checked == true
                    ? [
                        !settingNoti_Checked == true &&
                        settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !settingNoti_Checked == true &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && settingNoti_Checked == false
                    ? [
                        !settingNoti_Checked == true &&
                        settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        settingNoti_Checked == true &&
                        settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!settingNoti_Checked || editing"
                @click="settingNoti_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && settingNoti_Checked == true
                    ? [
                        !settingNoti_Checked == true &&
                        settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !settingNoti_Checked == true &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && settingNoti_Checked == false
                    ? [
                        !settingNoti_Checked == true &&
                        settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        settingNoti_Checked == true &&
                        settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !settingNoti_Checked == false &&
                            settingNoti_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="store_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('store')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!store_Checked || editing"
                @click="store_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && store_Checked == true
                    ? [
                        !store_Checked == true && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !store_Checked == true && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && store_Checked == false
                    ? [
                        !store_Checked == true && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        store_Checked == true && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!store_Checked || editing"
                @click="store_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && store_Checked == true
                    ? [
                        !store_Checked == true && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !store_Checked == true && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && store_Checked == false
                    ? [
                        !store_Checked == true && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        store_Checked == true && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !store_Checked == false && store_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="dashboard_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('dashboard')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!dashboard_Checked || editing"
                @click="dashboard_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && dashboard_Checked == true
                    ? [
                        !dashboard_Checked == true &&
                         dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !dashboard_Checked == true &&
                             dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !dashboard_Checked == false &&
                             dashboard_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && dashboard_Checked == false
                    ? [
                        !dashboard_Checked == true &&
                        dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !dashboard_Checked == false &&
                             dashboard_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        dashboard_Checked == true &&
                        dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !dashboard_Checked == false &&
                             dashboard_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!dashboard_Checked || editing"
                @click="dashboard_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && dashboard_Checked == true
                    ? [
                        !dashboard_Checked == true &&
                         dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !dashboard_Checked == true &&
                             dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !dashboard_Checked == false &&
                             dashboard_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && dashboard_Checked == false
                    ? [
                        !dashboard_Checked == true &&
                         dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !dashboard_Checked == false &&
                             dashboard_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        dashboard_Checked == true &&
                        dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : dashboard_Checked == false &&
                            dashboard_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !dashboard_Checked == false &&
                             dashboard_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("filter") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" class="pl-4">
              <v-checkbox
                :disabled="editing"
                v-model="skillMatrix_Checked"
                hide-details
                color="#a6cc39"
                class="permission_checkbox"
                :label="$t('trainingroadmapiso')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="padding-left: 60px">
              <v-btn
                :disabled="!skillMatrix_Checked || editing"
                @click="skillMatrix_Permission = 0"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && skillMatrix_Checked == true
                    ? [
                        !skillMatrix_Checked == true &&
                        skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !skillMatrix_Checked == true &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && skillMatrix_Checked == false
                    ? [
                        !skillMatrix_Checked == true &&
                        skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        skillMatrix_Checked == true &&
                        skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize mr-6"
                >{{ $t("View") }}</v-btn
              >
              <v-btn
                :disabled="!skillMatrix_Checked || editing"
                @click="skillMatrix_Permission = 1"
                style="font-size 14px;border-radius: 5px;box-shadow: unset;"
                :style="
                  editing == false && skillMatrix_Checked == true
                    ? [
                        !skillMatrix_Checked == true &&
                        skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !skillMatrix_Checked == true &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : editing == false && skillMatrix_Checked == false
                    ? [
                        !skillMatrix_Checked == true &&
                        skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : !skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                    : [
                        skillMatrix_Checked == true &&
                        skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 1
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#0000001f !important',
                              color: '#424242 !important',
                            }
                          : skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#ffffff',
                              color: '#A6CC39',
                              border: '1px solid #A6CC39',
                            }
                          : !skillMatrix_Checked == false &&
                            skillMatrix_Permission == 0
                          ? {
                              'background-color': '#ffffff !important',
                              color: '#424242 !important',
                              border: '1px solid #CCCCCC !important',
                            }
                          : { 'background-color': '#A6CC39', color: '#ffffff' },
                      ]
                "
                class="text-capitalize"
                >{{ $t("add") + "," + $t("edit") + "," + $t("delete") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider
        style="border: 1px solid #a6cc39"
        class="mt-7 mb-7"
      ></v-divider>
      <v-row class="mb-2">
        <v-col cols="12">
          <span style="font-size: 20px; color: #424242">{{
            $t("reports")
          }}</span>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            :disabled="editing"
            v-model="userActivity_Checked"
            class="permission_checkbox mr-3"
            :label="$t('useractivityrp')"
            color="#a6cc39"
            hide-details
          ></v-checkbox>
        </v-col>
        <!-- <v-col cols="2">
          <v-checkbox
            :disabled="editing"
            v-model="usageMetrics_Checked"
            class="permission_checkbox mr-3"
            :label="$t('usagemetrics')"
            color="#a6cc39"
            hide-details
          ></v-checkbox>
        </v-col> -->
        <v-col cols="3">
          <v-checkbox
            :disabled="editing"
            v-model="userView_Checked"
            class="permission_checkbox mr-3"
            :label="$t('contentviewrp')"
            color="#a6cc39"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            :disabled="editing"
            v-model="requestedUserRp_Checked"
            class="permission_checkbox mr-3"
            :label="$t('requesteduserreport')"
            color="#a6cc39"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            :disabled="editing"
            v-model="acReport_Checked"
            class="permission_checkbox"
            :label="$t('ACReport')"
            color="#a6cc39"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="#4FB14E" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </v-container>
</template>

<script>
export default {
  props: {
    search: String,
  },
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    permissionDefaultSetting_id: 0,
    editing_UsageType: true,
    editing: true,
    userActivity_Checked: true,
    userView_Checked: true,
    requestedUserRp_Checked: true,
    acReport_Checked: true,

    usage_Checked: true,
    library_Checked: true,
    lessonlearn_Checked: true,
    course_Checked: true,
    quiz_Checked: true,
    community_Checked: true,
    event_Checked: true,
    banner_Checked: true,
    feedbackbox_Checked: true,
    usermanage_Checked: true,
    companyProfile_Checked: true,
    sendnoti_Checked: true,
    settingNoti_Checked: true,
    store_Checked: true,
    skillMatrix_Checked: true,
    dashboard_Checked: true,

    mobileOrWebUsage_Permission: 1,
    library_Permission: 1,
    lessonLearn_Permission: 1,
    course_Permission: 1,
    quiz_Permission: 1,
    community_Permission: 1,
    event_Permission: 1,
    banner_Permission: 1,
    feedbackbox_Permission: 1,
    usermanage_Permission: 1,
    companyProfile_Permission: 1,
    sendnoti_Permission: 1,
    settingNoti_Permission: 1,
    editPermissionBtnShow: false,
    skillMatrix_Permission: 1,
    store_Permission: 1,
    dashboard_Permission: 1,
    dialog: false,
    editData: {},
    items: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [
      { title: 5 },
      { title: 10 },
      { title: 20 },
      { title: 30 },
      { title: 40 },
    ],
    loading: true,
  }),
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          align: "left",
          width: "100",
        },
        {
          text: this.$t("permissionid"),
          align: "left",
          value: "permissionID",
          class: "tableColor",
          width: "500",
        },
        {
          text: this.$t("permissionname"),
          align: "left",
          value: "permissionName",
          class: "tableColor",
        },
      ];
    },
  },
  watch: {
    mobileOrWebUsage_Permission(v) {
      if(v === 2){
        this.editing = true;
      }else{
        this.editing = false;
      }
    },
  },
  mounted() {
    this.getPermissionSetting();
  },
  methods: {
    editPermission(v) {
      let checkPermission = this.check_Permissions("usermanagement");

      if (checkPermission === false) {
        this.permissionDialog = true;
        this.permissionMessage =
          "You don't have permission to Update Permissions.";
      } else {
        if (v == "edit") {
          this.editPermissionBtnShow = true;
        } else if (v == "update") {
          this.editPermissionBtnShow = false;
        } else if (v == "cancel") {
          this.editPermissionBtnShow = false;
        }
        this.editing_UsageType = !this.editPermissionBtnShow; //web or mobile usage btn control
        this.editing =
          this.mobileOrWebUsage_Permission == 2
            ? true
            : !this.editPermissionBtnShow; 
      }
    },
    async getPermissionSetting() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        const res = await self.$axios.post(
          `${self.web_url}Permission/GetPermissionSetting?CompanyID=` +
            localStorage.getItem("companyID")
        );
        if (res.data.status == 0) {
          if (res.data.data != null) {
            this.permissionDefaultSetting_id =
              res.data.data.permissionSettingID;
            this.mobileOrWebUsage_Permission = res.data.data.permissionID;

          
            this.library_Checked = res.data.data.libraryFlag === null ? false : res.data.data.libraryFlag;
            this.library_Permission =
              res.data.data.libraryView === true ? 1 : 0;

            this.lessonlearn_Checked = res.data.data.lessonLearnFlag === null ? false : res.data.data.lessonLearnFlag;
            this.lessonLearn_Permission =
              res.data.data.lessonLearnView === true ? 1 : 0;

            this.course_Checked = res.data.data.courseFlag === null ? false : res.data.data.courseFlag;
            this.course_Permission = res.data.data.courseView === true ? 1 : 0;

            this.quiz_Checked = res.data.data.quizFlag === null ? false : res.data.data.quizFlag;
            this.quiz_Permission = res.data.data.quizView === true ? 1 : 0;

            this.community_Checked = res.data.data.communityFlag === null ? false : res.data.data.communityFlag;
            this.community_Permission =
              res.data.data.communityView === true ? 1 : 0;

            this.event_Checked = res.data.data.eventFlag === null ? false : res.data.data.eventFlag;
            this.event_Permission = res.data.data.eventView === true ? 1 : 0;

            this.banner_Checked = res.data.data.bannerFlag === null ? false : res.data.data.bannerFlag;
            this.banner_Permission = res.data.data.bannerView === true ? 1 : 0;

            this.feedbackbox_Checked = res.data.data.feedbackFlag === null ? false : res.data.data.feedbackFlag;
            this.feedbackbox_Permission =
              res.data.data.feedbackView === true ? 1 : 0;

            this.usermanage_Checked = res.data.data.userManagementFlag === null ? false : res.data.data.userManagementFlag;
            this.usermanage_Permission =
              res.data.data.userManagementView === true ? 1 : 0;

            this.companyProfile_Checked = res.data.data.companyProfileFlag === null ? false : res.data.data.companyProfileFlag;
            this.companyProfile_Permission =
              res.data.data.companyProfileView === true ? 1 : 0;

            this.sendnoti_Checked = res.data.data.sendNotificationFlag === null ? false : res.data.data.sendNotificationFlag;
            this.sendnoti_Permission =
              res.data.data.sendNotificationView === true ? 1 : 0;

            this.settingNoti_Checked = res.data.data.settingNotificationFlag === null ? false : res.data.data.settingNotificationFlag;
            this.settingNoti_Permission =
              res.data.data.settingNotificationView === true ? 1 : 0;

            this.store_Checked = res.data.data.storeFlag === null ? false : res.data.data.storeFlag;
            this.store_Permission = res.data.data.storeView === true ? 1 : 0;

            this.skillMatrix_Checked = res.data.data.skillMatrixFlag === null ? false : res.data.data.skillMatrixFlag;
            this.skillMatrix_Permission =
              res.data.data.skillMatrixView === true ? 1 : 0;

            this.dashboard_Checked = res.data.data.dashboardFlag === null ? false : res.data.data.dashboardFlag;
            this.dashboard_Permission =
              res.data.data.dashboardView === true ? 1 : 0;

            this.userActivity_Checked = res.data.data.userActivityFlag;
            this.userView_Checked = res.data.data.userViewFlag;
            this.requestedUserRp_Checked = res.data.data.requestedUserFlag;
            this.acReport_Checked = res.data.data.assignmentReportFlag;
          }
        }

        let itemCount = self.items ? self.items.length : 0;
        this.$emit("itemsCount", itemCount);
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see Permission Data.";
      }
      self.loading = false;
    },

    async CreateorUpdatePermissionSetting() {
      let that = this;
      that.editing = true;
      that.loading = true;

      let request = {
        createBy: localStorage.getItem("userName"),
        createDate: new Date().toISOString().substr(0, 10),
        updateBy: localStorage.getItem("userName"),
        updateDate: new Date().toISOString().substr(0, 10),

        activeFlag: true,
        companyID: localStorage.getItem("companyID"),

        permissionSettingID:
          this.permissionDefaultSetting_id != 0
            ? this.permissionDefaultSetting_id
            : 0,
        permissionID: this.mobileOrWebUsage_Permission, //usageID 1(web+mobile) 0r 2(mobile only)

        //menus => 1 is crud, 0 is only view permission.
        libraryFlag: this.library_Checked,
        libraryView: this.library_Permission === 1,

        lessonLearnFlag: this.lessonlearn_Checked,
        lessonLearnView: this.lessonLearn_Permission === 1,

        courseFlag: this.course_Checked,
        courseView: this.course_Permission === 1,

        quizFlag: this.quiz_Checked,
        quizView: this.quiz_Permission === 1,

        communityFlag: this.community_Checked,
        communityView: this.community_Permission == -1,

        eventFlag: this.event_Checked,
        eventView: this.event_Permission === 1,

        bannerFlag: this.banner_Checked,
        bannerView: this.banner_Permission === 1,

        feedbackFlag: this.feedbackbox_Checked,
        feedbackView: this.feedbackbox_Permission === 1,

        userManagementFlag: this.usermanage_Checked,
        userManagementView: this.usermanage_Permission === 1,

        companyProfileFlag: this.companyProfile_Checked,
        companyProfileView: this.companyProfile_Permission === 1,

        sendNotificationFlag: this.sendnoti_Checked,
        sendNotificationView: this.sendnoti_Permission === 1,

        settingNotificationFlag: this.settingNoti_Checked,
        settingNotificationView: this.settingNoti_Permission === 1,

        storeFlag: this.store_Checked,
        storeView: this.store_Permission === 1,

        skillMatrixFlag: this.skillMatrix_Checked,
        skillMatrixView: this.skillMatrix_Permission === 1,

        dashboardFlag: this.dashboard_Checked,
        dashboardView: this.dashboard_Permission === 1,

        userActivityFlag: this.userActivity_Checked,
        userViewFlag: this.userView_Checked,
        requestedUserFlag: this.requestedUserRp_Checked,
        assignmentReportFlag: this.acReport_Checked,
      };
      await that.$axios
        .post(
          `${that.web_url}Permission/CreateorUpdatePermissionSetting`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.getPermissionSetting();
            that.editPermissionBtnShow = false; // close update button
            that.loading = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },

  },
};
</script>
<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #e8e9ec !important;
  color: #4d4f5c !important;
  opacity: 1;
}
</style>

<style scoped>
.subtitle {
  color: #47484b;
  font-size: 16px;
}
::v-deep .permission_checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .permission_checkbox.mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .permission_checkbox.mdi-minus-box {
  color: #a6cc39 !important;
}
::v-deep .v-radio > .v-label {
  font-size: 12px !important;
  color: #424242 !important;
}
::v-deep .radio_label .v-icon.v-icon {
  font-size: 20px !important;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #47484b;
}
</style>

<style scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: unset !important;
}
.theme--light.v-btn.v-btn--disabled {
  color: unset !important;
}
</style>

<style>
.accessright {
  font-size: 16px;
  color: #424242;
}
</style>