<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <v-img
            src="@/assets/images/department-line.svg"
            class="mr-0 adddepartment"
          ></v-img>
          <p>{{ $t("adddepartmentone") }}</p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              $store.commit('clearSelected'),
                $emit('close'),
                (department = []),
                $refs.form.reset()
            "
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
              margin-bottom: 16px;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="9">
                <label for="deparmentone" class="font-style"
                  >{{ $t("deparmentone") }}
                  <span style="color: red">*</span></label
                >
                <v-text-field
                  :rules="nameRules"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="department.name"
                  class="mt-2"
                ></v-text-field>
              </v-col>
              <v-col cols="2"></v-col>
              <v-col cols="9">
                <label for="memberlistsm" class="font-style"
                  >{{ $t("memberlistsm") }}
                  <span style="color: red">*</span></label
                >
                <v-combobox
                  :rules="memberRules"
                  append-icon=""
                  :items="users"
                  readonly
                  multiple
                  item-text="fullName"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="department.list"
                  class="mt-2"
                ></v-combobox>
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="secondary"
                  width="120"
                  block
                  class="mt-8 font-style text-capitalize"
                  @click="
                    $emit('close'),
                      $store.commit('closeAddDepartmentDialog'),
                      $store.commit('DepartmentTable', true),
                      $store.commit('saveSearchandBtnFlag', false)
                  "
                  >{{ $t("add") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            text
            width="100"
            class="text-capitalize btnfont-style"
            @click="
              $store.commit('clearSelected'),
                $emit('close'),
                (department = []),
                $refs.form.reset()
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="secondary"
            width="100"
            class="text-capitalize btnfont-style"
            @click="submit()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "selectedUserList",
      "levelList",
      "addNoUserDepartmentDialog",
    ]),
    dialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    watchingSelected() {
      return this.selectedUserList;
    },
    idRules() {
      return [(v) => !!v || "ID is required"];
    },
    nameRules() {
      return [(v) => !!v || "Name is required"];
    },
    memberRules() {
      return [(v) => !!v || "Member list is required"];
    },
    levelRules() {
      return [(v) => !!v || "Level is required"];
    },
    selectAll() {
      return this.department.level
        ? this.department.level.length === this.levelList.length
        : 0;
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
  },
  data: () => ({
    searchgroup: null,
    valid: false,
    levels: [],
    department: {
      id: "",
      name: "",
      list: "",
      // level: "",
      level: [],
    },
    selected: [],
    group: [],
    users: [],
  }),
  mounted() {
    this.getRoleAndPermission();
  },
  watch: {
    watchingSelected(val) {
      if (val) {
        this.getMemberList();
      }
    },
  },
  methods: {
    getSelectAll() {
      if (this.department.level.length == 0) {
        this.department.level = this.levelList.map(
          ({ userRoleID }) => userRoleID
        );
      } else {
        this.department.level = [];
      }
    },
    async getRoleAndPermission() {
      const res = await this.$axios.post(
        `${this.web_url}RoleAndPermission/GetRoleForUserAndGroup?companyid=` +
          localStorage.getItem("companyID")
      );
      this.levels = res.data.data;
      this.$store.commit("saveroleList", res.data.data);
    },
    getMemberList() {
      let tempList = [];
      for (let i = 0; i < this.selectedUserList.length; i++) {
        tempList.push(this.selectedUserList[i].fullName);
      }
      this.department.list = tempList;
      this.selected = this.selectedUserList;
    },
    async getDept() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      this.$store.commit("savedeparmentList", res.data.data);
      let dept = [];
      for (let i = 0; i < res.data.data.length; i++) {
        dept.push({
          departmentID: res.data.data[i]["groupID"],
          departmentName: res.data.data[i]["groupName"],
        });
      }
      this.$store.commit("editeparmentList", dept);
    },
    async submit() {
      if (this.$refs.form.validate() && this.selectedUserList != 0) {
        const userIds = this.selectedUserList.map((user) => user.id);

        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const request = {
          departmentName: this.department.name,
          userId: userIds,
          companyId: localStorage.getItem("companyID"),
          createOrUpdateBy: memberid,
        };

        const response = await this.$axios.post(
          `${this.web_url}Department/AddUserDepartment`,
          request
        );
        if (response.data.status == 0) {
          this.department = [];
          this.$refs.form.reset();
          this.getDept();
          this.$emit("confirm");
        }
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
/* >>>.v-btn__content{
  font-size: 20px;
} */
.btnfont-style {
  font-size: 16px;
}
::v-deep .v-label {
  font-size: 16px !important;
  color: #4d4f5c !important;
}
::v-deep .v-input__slot {
  font-size: 16px !important;
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important ;
}
</style>

<style scoped>
/* width */
::v-deep ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::v-deep ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::v-deep ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

/* Handle on hover */
::v-deep ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.adddepartment {
  margin-bottom: 15px;
  max-width: 30px;
}
</style>
